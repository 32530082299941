import { useParams } from "react-router-dom";
// import { useSearchParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react'
import { reverse } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

// import Header from 'components/Header'
// import { Sidebar } from 'components/sidebar'
import { SoundProvider } from './providers/SoundProvider'
// import { getUuid } from 'utils'
import { getContent } from './api'
import { useContent } from './providers/ContentProvider'

import Chat from './chat/Chat'
import { twMerge } from "tailwind-merge";
import { getUdid } from "../../utils/request";
// import { StartModal } from './StartModal'


const id = getUdid()

export const MainChat = ({modalMode = false}) => {
  // document.cookie = `uuidv4=${id}`
  const { diseaseCode, photoId } = useParams({ strict: false });
  // const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
  //   useSearch({
  //     strict: false
  //   });

  const location = useLocation();
  // Parse the search string
  const searchParams = new URLSearchParams(location.search);

  // Extract UTM parameters
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const utm_term = searchParams.get("utm_term");
  const utm_content = searchParams.get("utm_content");

  const { setContent } = useContent()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      const result = await getContent({
        id,
        diseaseCode,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        scoreId: photoId,
      })
      if (result) {
        setIsLoading(false)
        setContent({
          ...result,
          userStartMessage: result.message,
          currentFlow: result.flow,
          historyMessages: [
            ...reverse(
              result.history.map(
                (message) => ({
                  text: message.img ? message.img : message.text,
                  id: uuidv4(),
                  sender: message.class.includes('left') ? 'AI' : 'user',
                  type: message.img ? 'image' : 'text'
                })
              )
            ),
            // ...reverse(
            //   result.start.map((message) => ({
            //     text: message,
            //     id: uuidv4(),
            //     sender: 'AI',
            //     type: 'text'
            //   }))
            // )
          ]
        })
      }
    }
    setIsLoading(true)
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diseaseCode, utm_campaign, utm_content, utm_medium, utm_source, utm_term])

  return (
    <div className={twMerge(" font-SFProText", modalMode  ? 'h-full w-full border-t border-t-[#d4d4d4] border-solid' : 'min-h-svh lg:max-w-[900px] lg:grid-cols-[1fr_35%] mx-auto grid border-x border-gray-300  shadow-[0_0_2px_0_rgba(0,0,0,0.16),_0_2px_12px_0_rgba(0,0,0,0.12)]')}>
      <div className="flex h-full flex-col font-SFProText">
        <SoundProvider>
          {/* <Header /> */}
          <div className="flex flex-1 flex-col overflow-hidden">
            {id && !isLoading && <Chat id={id} />}
          </div>
        </SoundProvider>
      </div>
      {/* <div className="hidden lg:block">
        <Sidebar />
      </div> */}
      {/* <StartModal /> */}
    </div>
  )
}
