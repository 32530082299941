import { useCallback, useReducer, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { getUdid, makeRequest } from "../utils/request";
import { useOpenChatModal, useOpenInviteModal, useSelfieMode, useShowChatOnExpress, useShowInviteModalExpress, } from ".";
import { getPaymentRequest, imageUrlToBase64, loadResultScore } from "../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@uidotdev/usehooks";




const initialState = {
    photoFullFace: {
        url: null,
        id: null,
    },
    photoFullFaceError: null,
    photoProfile: {
        url: null,
        id: null,
    },
    previewData: {
        score: 0,
        status: '',
        texts: [],
        previewImage: null,
    },
    profileLoad: {
        show: false,
        onLoad: null,
    },
    loading: false,
    loadingValidation: false,
    photoProfileError: null,
    showingPaywall: false,
    showingPreview: false,
    showingAuth: false,
    loadingHeader: '',
    loadingText: '',
    loadingSrc: null,
    scoreId: null,
}

function reducer(state, action) {
    if (action.type === 'reset') {
        return initialState;
    }
    if (action.type === 'loading_img') {
        return {
            ...state,
            loadingSrc: action.src,
        }
    }

    if (action.type === 'start_loading') {
        return {
            ...state,
            loading: true,
            loadingHeader: action.header,
            loadingText: action.text,
            loadingValidation: action.loadingValidation || false,
        }
    }

    let modifiedState = { ...state, loading: false, };

    if (action.type === 'loaded_full_face') {
        return {
            ...modifiedState,
            photoFullFace: {
                url: action.url,
                id: action.id,
            },
            photoFullFaceError: null,
        }
    }


    if (action.type === 'show_auth') {
        return {
            ...modifiedState,
            showingAuth: true,
            onGetEmail: action.onGetEmail
        }
    }

    if (action.type === 'close_auth') {
        return {
            ...modifiedState,
            showingAuth: false,
        }
    }

    if (action.type === 'show_preview') {
        return {
            ...modifiedState,
            showingPreview: true,
            previewData: {
                previewImage: action.previewImage,
                blocks: action.blocks,
                viewReady: action.viewReady,
                selfieUrl: action.selfieUrl
            },
            onGoFromExpress: action.onGoFromExpress,
        }
    }

    if (action.type === 'close_preview') {
        return {
            ...modifiedState,
            showingPreview: false,
        }
    }

    if (action.type === 'show_paywall') {
        return {
            ...modifiedState,
            showingPaywall: true,
            onGoPay: action.onGoPay,
            showingAuth: false,
        }
    }
    if (action.type === 'close_paywall') {
        return {
            ...modifiedState,
            showingPaywall: false,
        }
    }

    if (action.type === 'set_score') {
        return {
            ...modifiedState,
            scoreId: action.scoreId
        }
    }

    if (action.type === 'loaded_profile') {
        return {
            ...modifiedState,
            photoProfile: {
                url: action.url,
                id: action.id,
            },
            photoProfileError: null,
            profileLoad: {
                show: false,
                onLoad: null,
            }
        }
    }

    if (action.type === 'profile_photo_load') {
        return {
            ...modifiedState,
            profileLoad: {
                show: true,
                onLoad: action.onLoad,
            },
        }
    }

    if (action.type === 'full_face_error') {
        return {
            ...modifiedState,
            photoFullFaceError: {
                text: action.text,
                url: action.url,
                onLoad: action.onLoad,
            }
        };
    }
    if (action.type === 'profile_error') {
        return {
            ...modifiedState,
            photoProfileError: {
                text: action.text,
                url: action.url,
                onLoad: action.onLoad
            }
        };
    }
}

export const PhotoLoadingContext = createContext({
    modalLoadingOpened: false,
    loadingProgres: true,
})

PhotoLoadingContext.displayName = 'AppContext'


export const PhotoLoadingContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const showInviteAfterClose = useShowInviteModalExpress()
    const [paymentInformation, setPaymentInformation] = useLocalStorage(null);

    const [modalLoadingOpened, setModalLoadingOpened] = useState(false);

    const selfieMode = useSelfieMode();
    const history = useHistory();

    const { t } = useTranslation();
    const openInviteModal = useOpenInviteModal();

    const openModal = useCallback(() => {
        setModalLoadingOpened(true);
    }, [])

    const closeModal = useCallback(() => {
        setModalLoadingOpened(false);
        if(showInviteAfterClose){
            openInviteModal();
        }
    }, [showInviteAfterClose, openInviteModal])

    const getStage = useCallback(() => {
        if (state.loading) {
            return 'loading';
        }

        if (state.showingPaywall) {
            return 'paywall';
        }

        if (state.showingPreview) {
            return 'preview';
        }

        if (state.showingAuth) {
            return 'auth';
        }

        if (state.photoFullFaceError) {
            return 'fullFaceError';
        }

        if (state.photoProfileError) {
            return 'profileError';
        }

        if (state.profileLoad.show) {
            return 'profileLoad'
        }

        return 'loading';
    }, [state])


    const uploadPhoto = async (photoBlob, selfieType) => {
        const formData = new FormData();
        formData.append('file', photoBlob)
        formData.append('selfie_type', selfieType);
        const result = await makeRequest(`/selfie/upload`, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result;
    }

    const uploadFirstPhoto = useCallback(async (photoBlob, photoSrc) => {

        return new Promise(async (resolve, reject) => {
            openModal();

            dispatch({
                type: 'start_loading',
                header: t('LOADING_UPLOAD_TITLE'),
                text: t('LOADING_UPLOAD_SUBTITLE')
            });


            const loadFunc = (photoBlob, photoSrc) => {

                dispatch({
                    type: 'loading_img',
                    src: photoSrc
                })

                uploadFirstPhoto(photoBlob, photoSrc).then((result) => {
                    resolve(result);
                })
            }

            const result = await uploadPhoto(photoBlob, 0);

            if (!result.data.status || result.data.result.moderation.status !== 1) {
                dispatch({
                    type: 'full_face_error',
                    text: result.data.message || result.data.result?.moderation?.comment || 'Internet Error',
                    url: result.data?.result?.url || photoSrc,
                    onLoad: loadFunc,
                })
                return;
            }

            let resultImage = null
            if (result.data?.result?.url) {
                resultImage = await imageUrlToBase64(result.data?.result?.url);
            } else {
                resultImage = photoSrc
            }

            const photoId = result.data.result.selfie_id;

            dispatch({
                type: 'start_loading',
                header: t('LOADING_UPLOAD_TITLE'),
                text: t('LOADING_UPLOAD_SUBTITLE'),
                loadingValidation: true,
            });

            const creationResult = await makeRequest(`/score/create`, 'post', {
                selfie_id: photoId
            });

            if (!creationResult.data.status) {
                dispatch({
                    type: 'full_face_error',
                    text: creationResult.data.message || 'Internet Error',
                    url: creationResult.data?.result?.url || photoSrc,
                    onLoad: loadFunc,
                })
                return;
            }


            dispatch({
                type: 'loaded_full_face',
                url: resultImage,
                id: photoId,
            });

            const { score_id } = creationResult.data.result;

            resolve({
                scoreId: score_id,
                showPaywall: !!creationResult.data.result.block_by_paywall,
                expressSkipPaywall: !!creationResult.data.result.is_paywall_skip,
                showExpress: !!creationResult.data.result.is_express,
                showAuth: !!creationResult.data.result.block_by_auth,
                paymentProject: creationResult.data.result.paywall.project_id,
                paymentItem: creationResult.data.result.paywall.item_id
            })

        })
    }, [openModal, t])

    const uploadSecondPhoto = useCallback((scoreId) => {
        return new Promise(async (resolve) => {

            // show screen
            const loadFunc = async (photoBlob, photoSrc) => {
                dispatch({
                    type: 'start_loading',
                    header: t('LOADING_UPLOAD_TITLE'),
                    text: t('LOADING_UPLOAD_SUBTITLE')
                })

                const result = await uploadPhoto(photoBlob, 2);

                if (!result.data.status || result.data.result.moderation.status !== 1) {
                    dispatch({
                        type: 'profile_error',
                        text: result.data.result?.moderation?.comment || 'Internet Error',
                        url: result.data?.result?.url || photoSrc,
                        onLoad: loadFunc,
                    })
                    return;
                }
                dispatch({
                    type: 'start_loading',
                    header: t('LOADING_UPLOAD_TITLE'),
                    text: t('LOADING_UPLOAD_SUBTITLE'),
                    loadingValidation: true,
                });
                const updateResult = await makeRequest(`/score/update`, 'post', {
                    selfie_side_id: result.data.result.selfie_id,
                    score_id: scoreId,
                });


                if (!updateResult.data.status) {
                    dispatch({
                        type: 'profile_error',
                        text: updateResult.data.result?.moderation?.comment || 'Internet Error',
                        url: updateResult.data?.result?.url || photoSrc,
                        onLoad: loadFunc,
                    })
                    return;
                }
                dispatch({
                    type: 'loaded_profile',
                    url: result.data?.result?.url || photoSrc,
                    id: result.data.result.selfie_id
                });

                resolve();
            }

            dispatch({
                type: 'profile_photo_load',
                onLoad: loadFunc,
            })

        })
    }, [t, dispatch]);


    const runAuth = useCallback(() => {
        return new Promise((resolve, reject) => {

            const onAuth = (email) => {
                makeRequest('/user/update', 'post', {
                    email,
                });
                dispatch({
                    type: 'close_auth'
                });
                resolve();
            }

            dispatch({
                type: 'show_auth',
                onGetEmail: onAuth,
            });
        })
    }, [dispatch])

    const runExpress = useCallback((scoreId, expressPaywall, paymentProject, paymentItem) => {
        return new Promise(async (resolve) => {

            dispatch({
                type: 'start_loading',
                header: t('LOADING_SCORE_EXPRESS_TITLE'),
                text: t('LOADING_SCORE_EXPRESS_SUBTITLE'),
                loadingValidation: true,
            });

            const creationScoreResult = await makeRequest('/score/express/create', 'post', {
                score_id: scoreId
            }, {}, false);

            const scoreResult = await loadResultScore(scoreId, 'none', true)
            const onLoad = async () => {

                if (expressPaywall) {
                    makeRequest('/user/update', 'post', {
                        tap: 'stripe',
                    });

                    makeRequest('/user/update', 'post', {
                        tap: 'get_full_report',
                    });

                    window.dataLayer.push({ 'event': 'go_to_stripe' });
                    if (paymentItem && paymentProject) {
                        const res = await getPaymentRequest(getUdid(), paymentItem, paymentProject, scoreId)
                        if (res.data.redirect_url) {
                            window.location.href = res.data.redirect_url;
                        } else {
                            history.push('/err')
                        }
                        return;
                    }

                    resolve();
                    return;
                }

                makeRequest('/user/update', 'post', {
                    tap: 'get_full_report',
                });

                dispatch({
                    type: 'close_preview'
                });

                resolve();
            }

            dispatch({
                type: 'show_preview',
                previewImage: scoreResult.data.result.cards.image.url,
                blocks: scoreResult.data.result?.data || [],
                viewReady: scoreResult.data.result.is_view_ready === 1,
                selfieUrl:  scoreResult.data.result.selfie_url,
                onGoFromExpress: onLoad
            });
        })
    }, [t, history]);

    const runPaywall = useCallback((paymentProject, paymentItem, scoreId) => {
        return new Promise(async (resolve) => {

            const onLoad = async () => {
                makeRequest('/user/update', 'post', {
                    tap: 'stripe',
                });
                window.dataLayer.push({ 'event': 'go_to_stripe' });

                if (paymentItem && paymentProject) {
                    const res = await getPaymentRequest(getUdid(), paymentItem, paymentProject, scoreId)
                    if (res.data.redirect_url) {
                        window.location.href = res.data.redirect_url;
                    } else {
                        history.push('/err')
                    }
                    return;
                }

                resolve();
            }

            dispatch({
                type: 'show_paywall',
                onGoPay: onLoad,
            })

        }, [])
    }, [history]);


    const loadResult = useCallback(async (scoreId) => {
        dispatch({
            type: 'start_loading',
            header: t('LOADING_SCORE_ADVANCED_TITLE'),
            text: t('LOADING_SCORE_ADVANCED_SUBTITLE'),
            loadingValidation: true,
        })

        await makeRequest('/score/advanced/create', 'post', {
            score_id: scoreId,
        });

        loadResultScore(scoreId, 'none').then(() => {
            window.dataLayer.push({ 'event': 'go_to_result_page' });
            history.push(`/${scoreId}`);
        })
    }, [dispatch, history, t])

    const pipeline = useCallback(async (photoBlob, photoSrc) => {

        dispatch({
            type: 'loading_img',
            src: photoSrc
        })

        const { scoreId, showPaywall, showExpress, showAuth, paymentProject, paymentItem, expressSkipPaywall } = await uploadFirstPhoto(photoBlob, photoSrc);
        setPaymentInformation({paymentProject, paymentItem, scoreId})
        dispatch({
            type: 'set_score',
            scoreId: scoreId
        })

        if (selfieMode === 'two') {
            await uploadSecondPhoto(scoreId);
        }

        if (showAuth) {
            await runAuth();
        }

        if (showExpress) {
            await runExpress(scoreId, expressSkipPaywall, paymentProject, paymentItem,);
        }

        if (showPaywall) {
            await runPaywall(paymentProject, paymentItem, scoreId);
        }

        await loadResult(scoreId);
    }, [uploadFirstPhoto, uploadSecondPhoto, runAuth, runExpress, runPaywall, selfieMode, loadResult])


    const reset = useCallback(() => {
        dispatch({
            type: 'reset'
        });
    }, [dispatch])

    const value = {
        state,
        getStage,
        modalLoadingOpened,
        dispatch,
        reset,
        closeModal,
        pipeline,
        paymentInformation,
        loadResult,
    }

    return <>
        <PhotoLoadingContext.Provider value={value}>
            {children}
        </PhotoLoadingContext.Provider>
    </>
}

export const usePhotoState = () => useContextSelector(PhotoLoadingContext, value => value.state)
export const useGetStage = () => useContextSelector(PhotoLoadingContext, value => value.getStage)
export const useDispatch = () => useContextSelector(PhotoLoadingContext, value => value.dispatch)
export const useReset = () => useContextSelector(PhotoLoadingContext, value => value.reset)
export const useModalLoadingOpened = () => useContextSelector(PhotoLoadingContext, value => value.modalLoadingOpened)
export const useCloseModal = () => useContextSelector(PhotoLoadingContext, value => value.closeModal)
export const useOnStartLoadPhoto = () => useContextSelector(PhotoLoadingContext, value => value.pipeline);
export const usePaymentInformation = () => useContextSelector(PhotoLoadingContext, value => value.paymentInformation);
export const useGoToResult = () => useContextSelector(PhotoLoadingContext, value => value.loadResult);
