import axios from "axios"
import { v4 as uuidv4 } from 'uuid'


const AXIOS_BASE_URL = process.env.REACT_APP_API_LINK + '/'

export const getContent = async ({
  id,
  diseaseCode,
  utm_source,
  utm_medium,
  utm_campaign,
  utm_term,
  utm_content,
  scoreId
}) => {
  try {
    const data = {
      udid: id,
      disease_code: diseaseCode,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      score_id:scoreId,
    }
    const response = await axios.post(`${AXIOS_BASE_URL}/chat/prepare`, data, {
      // withCredentials: true
    })
    return {
      ...response.data.result.data,
      flow: response.data.result.flow,
      history: response.data.result.history
    }
  } catch (error) {
    console.error('Error getting content', error)
  }
}

export const sendMessage = async ({
  text,
  image,
  id,
  mode = 'upload',
  scoreId
}) => {
  try {
    const data = {
      udid: id,
      ...(text && { text }),
      ...(image && { image }),
      ...((mode === 'express' || mode === 'payment' || mode === 'result') && { score_id: scoreId })
    }
    const response = await axios.post(`${AXIOS_BASE_URL}chat/${mode}`, data, {
      // withCredentials: true
    })
    return {
      text: response.data.result.text,
      id: uuidv4(),
      options: response.data.result.options ?? [],
      flow: response.data.result.flow,
      button: response.data.result.button,
    }
  } catch (error) {
    console.error('Error sending message', error)
  }
}

export const getVoiceMessage = async ({
  text,
  id
}) => {
  try {
    const response = await axios.post(
      `${AXIOS_BASE_URL}api/voice/`,
      { text, udid: id },
      {
        //   withCredentials: true,
        responseType: 'arraybuffer'
      }
    )

    return { audio: response.data }
  } catch (error) {
    console.error('Error sending message:', error)
  }
}